import React, {useEffect, useState} from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button
} from "@material-tailwind/react";
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
import { RxCross1 } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { sendBiddersTqsEmail } from "../../features/TenderDetail/TenderDetailSlice";
import { useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';

const TechnicalQueryModal = ({modalStatus, setModalStatus}) => {
  let { id } = useParams();
  const recipient_emails = ["akash.rawat@valiancesolutions.com", "arushi.verma@valiancesolutions.com"]

  const { generateTQStatus, generateTQData, biddersTqsEmailStatus } = useSelector((state) => state.tenderDetail)
  const [selectedBidders, setSelectedBidders] = useState([])
  const dispatch = useDispatch()

  const handleOpen = (value) => {
    setModalStatus(value);
    resetOnModalClose()
  };

  const selectBidderClickHandler = (index) => {
    if(selectedBidders.includes(index)){
      setSelectedBidders(selectedBidders.filter(item => item !== index))
    }
    else{
      setSelectedBidders([...selectedBidders, index])
    }
  }

  const checkTQPresentOrNot = () => {
    let flag = false
    for(let i=0; i<generateTQData?.bidders?.length; i++){
        if(selectedBidders.includes(generateTQData?.bidders?.[i]?.bidder_id)){
          const tqs = generateTQData?.bidders?.[i]?.tqs
          if(tqs.length>0){
            flag=true
          }
       }
    }

    if(flag){
      return true
    }
    else{
      // toast.error("No TQs found for selected bidders")
      alert("No TQs found for selected bidders")
      return false
    }
  }

  const sendEmailHandler = () => {
    if(checkTQPresentOrNot()){
      dispatch(sendBiddersTqsEmail({id: id, data: {bidder_ids: selectedBidders, recipient_emails: recipient_emails}}))
    }
  }

  // Close modal when api call is successful
  useEffect(() => {
    if(biddersTqsEmailStatus === "succeeded"){
      setModalStatus(false)
      resetOnModalClose()
    }
  }, [biddersTqsEmailStatus])

  const resetOnModalClose = () => {
    setSelectedBidders([])
  }

  const bidderSelectionClickHandler = (flag) => {
    if(flag === "select"){
      setSelectedBidders(generateTQData?.bidders?.map((item, index) => index+1))
    }
    else{
      setSelectedBidders([])
    }

  }




  return (
    <Dialog
        open={modalStatus}
        handler={() => handleOpen(false)}
        size="xl"
      >
        <DialogHeader className="flex justify-between">
          Technical Queries  
          <RxCross1 color="red" style={{ cursor: "pointer" }} onClick={() => handleOpen(false)} />
        </DialogHeader>

        <DialogBody className="h-[80vh]">
            {
                generateTQStatus === "loading" ? 
                <div className="flex flex-col gap-8 items-center justify-center h-full">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                    Loading...
                </div>
                :
                <div className="h-full flex flex-col gap-8 w-full">

                    <div className="flex gap-8 items-center justify-between">
                      <div>
                        <span className="font-bold text-[green] text-base">{selectedBidders.length}</span>
                        <span className="text-[#5B5B5B] font-bold text-base">/{generateTQData?.bidders?.length} Selected</span>
                      </div>
                      <div className="flex gap-6">
                        <div>
                          <Button size="sm" variant="outlined" onClick={() => bidderSelectionClickHandler("select")}>Select All</Button>
                        </div>
                        <div>
                          <Button size="sm" variant="outlined" onClick={() => bidderSelectionClickHandler("deselect")}>Deselect All</Button>
                        </div>
                      </div>
                    </div>

                    <div 
                    className="flex flex-col gap-4 h-full overflow-scroll p-4" 
                    style={{border: "1px solid black"}}>
                        {
                            (Array.isArray(generateTQData?.bidders) && generateTQData?.bidders?.length>0) ?
                            generateTQData?.bidders.map((data, index) => (
                                <div key={index} onClick={() => selectBidderClickHandler(index+1)} className={`flex flex-col gap-2 p-4 cursor-pointer ${selectedBidders.includes(index+1) ? "bg-[#cefac0]" : "bg-[#2423250a]"}`} style={{border: `${selectedBidders.includes(index+1) ? "1.5px solid green" : "1.5px solid #DCDEE4"}`}}>
                                    <div className="font-extrabold text-[#212663] text-xl">{data?.bidder_name ?? "NA"}</div>
                                    <div className="flex flex-col gap-2 text-[#242325] font-bold text-base">
                                    {
                                        (Array.isArray(data?.tqs) && data?.tqs?.length>0) ?
                                          data?.tqs?.map((tq, tqIndex) => {
                                          const validEntry = Object.entries(tq).find(
                                              ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
                                          );
                                          const [key] = validEntry || [];

                                          return (
                                              <span key={tqIndex}>&#8226; {key ?? "No valid key"}</span>
                                          );
                                          })
                                        :
                                        <div className="text-[green]">No PQRs</div>
                                    }
                                    </div>
                                </div>
                            ))
                            :
                            <div className="font-extrabold text-[#212663] text-xl flex items-center justify-center h-full">No Bidders</div>
                        }
                            
                    </div>

                    <div className="">
                        <Button disabled={selectedBidders.length === 0 ? true : false} loading={biddersTqsEmailStatus === "loading" ? true : false} onClick={sendEmailHandler}>
                            Send Email
                        </Button>
                    </div>
                </div>
            }
        </DialogBody>

        <DialogFooter className="space-x-2"></DialogFooter>
      </Dialog>
  )
}

export default TechnicalQueryModal