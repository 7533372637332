import React, {useState, useEffect} from 'react'
import DocumentUploadButton from '../../Components/DocumentUploadButton'
import TenderDocument from './TenderDocument'
import BidderTable from './BidderTable'
import UploadDocument from "../../Components/modals/UploadDocument"
import { Button } from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import ReviewModal from './ReviewModal'
import { useSelector } from 'react-redux'
import { Tooltip } from "@material-tailwind/react";
import { tenderStatus } from '../../utils/TenderConstants'
import { useParams } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';


const TenderBottombar = ({uploadDocumentModalStatus,setUploadDocumentModalStatus , doctext , setDocText, type, setType}) => {
  let { id } = useParams();
  const {bidderDetail, tenderStatDetail, is_all_bidders_reviewed} = useSelector(state => state.tenderDetail)

  const [reviewModal, setReviewModal] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)
  const [reviewDone, setReviewDone] = useState(false)
  const [bidderReviewDoneCount, setBidderReviewDoneCount] = useState(0)
  const [genAIProcessCompleted, setGenAIProcessCompleted] = useState(false)

  // Updating PQRs reviwed count
  useEffect(() => {
    if(Array.isArray(bidderDetail)){
      let count = 0
      for(let i=0; i<bidderDetail?.length; i++){
        if(bidderDetail[i]?.pqrs_reviewed){
          count ++
        }
      }
      setBidderReviewDoneCount(count)
    }
  }, [bidderDetail])

  // Checking whether genAI process is completed
  useEffect(() => {
    setGenAIProcessCompleted(tenderStatDetail?.status === tenderStatus.consolidatedStatementGenerated ? true : false ?? false)
  }, [tenderStatDetail])


  const reviewModalClickHandler = (val) => {
    if(!genAIProcessCompleted){
      return
    }

    if(is_all_bidders_reviewed){
      toast.error("Bidders are already reviewed!!")
      return
    }

    if(val === "all"){
      setSelectedModal("all")
      // setSelectedModal("single")
    }
    else{
      setSelectedModal("single")
    }
    setReviewModal(true)
  }
  

  return (
    <div className='h-full w-full flex flex-col flex-wrap lg:flex-nowrap gap-4 overflow-visible lg:overflow-hidden'>

      <ReviewModal modalStatus={reviewModal} setModalStatus={setReviewModal} selectedModal={selectedModal} setReviewDone={setReviewDone} docID={id}/>

      <div className=''>
        <div className='flex flex-row items-center justify-between gap-4'>
          <div className='text-[#8d6641] font-bold text-2xl'>
            Bidder Table
          </div>
          <div className='flex flex-row items-center gap-4'>
            <div>
              <span className='text-[#2AC239] font-bold'>{bidderReviewDoneCount}</span>
              <span className='text-[#737373] font-bold'> /{bidderDetail?.length} Bidders Review Completed</span>
            </div>
            <div>
            {
              !genAIProcessCompleted ? 
              <Tooltip content="Processing, please wait until the process is completed">
                <Button className={`flex items-center gap-2 normal-case text-[12px] rounded-full bg-[#a1a3a6]`} size="sm">
                  Mark All Bidders Reviewed
                  <FaCheckCircle size={15}/>
                </Button>
              </Tooltip>
              :
              <Button 
                onClick={() => reviewModalClickHandler("all")} 
                className={`flex items-center gap-2 normal-case text-[12px] rounded-full ${is_all_bidders_reviewed ? "bg-green-500 text-white" : "bg-[#212663] text-white"}`} size="sm"
              >
                {
                  is_all_bidders_reviewed ? "Review Completed" : "Mark Bidders Reviewed"
                }
                  <FaCheckCircle size={15}/>
              </Button>
            }
            
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#FFFFFF] overflow-hidden flex-grow w-full lg:w-auto'>
          <BidderTable type={type} />
      </div>
    </div>

  )
}

export default TenderBottombar